import { preprocessCoin } from '../../../coinUtils';
import CanvasCollection from '../CanvasCollection';

function CryptoCoinsCollection() {
  CanvasCollection.apply(this, arguments);
  this.name = 'CryptoCoinsCollection';
}

CryptoCoinsCollection.prototype = Object.create(CanvasCollection.prototype);
CryptoCoinsCollection.prototype.constructor = CryptoCoinsCollection;

CryptoCoinsCollection.prototype.addTimestamp = function (data) {
  if (data['updated_at']) {
    data['timestamp'] = new Date(data['updated_at']).getTime();
  }
  return this;
}

CryptoCoinsCollection.prototype.getTickers = function () {
  const coins = [];
  for (let i in this.items) {
    if (this.items[i]['id']) {
      coins.push(this.items[i]['id']);
    }
  }
  return coins;
};

CryptoCoinsCollection.prototype.checkItem = function(item) {
  if (
    item['symbol'] &&
    item['id'] &&
    item['change_percent_24hr'] &&
    item['updated_at'] &&
    item['name'] &&
    item['price'] &&
    item['volume_usd_24hr'] &&
    item['history_1_day']
  ) {
    item['type'] = 'coin';
    // item['name'] = String('TEST COIN NAME LIMIT COIN LIMIT TEST NAME TEST LIMIT COIN NAME').substr(0, Math.round(10 + 20 * Math.random()));
    return true;
  }
  console.warn('wrong coin data', item);
  return false;
};

CryptoCoinsCollection.prototype.getNext = function(categories, ids = []) {
  if (typeof this.count === 'undefined') {
    this.count = 0;
  } else {
    this.count = this.count + 1;
  }
  let min;

  if (this.items) {
    if (categories.length === 2 && categories[0] === 'coin' && categories[1] === 'added' && this.hasNew()) {
      for (let i = 0; i < this.items.length; i++) {
        if (categories[1] === 'added' && this.items[i].added) {
          min = this.items[i];
        }
      }
    } else if (categories.length === 2 && categories[0] === 'coin' && categories[1] !== 'added') {
      for (let i = 0; i < this.items.length; i++) {
        if (categories[1] === this.items[i].id) {
          min = this.items[i];
        }
      }
    } else {
      for (let i = 0; i < this.items.length; i++) {
        if (ids.indexOf(this.items[i].id) > -1) continue;
        if (this.items[i].added) continue;
        if (!min || this.items[i].order < min.order) {
          min = this.items[i];
        }
      }
      if (!min && this.items.length === 1) {
        min = this.items[0];
      }
    }
  } else {
    console.warn('collection items undefined');
    return null;
  }

  if (min) {
    min.order = ++this.order;
    min.added = false;
    return min;
  } else {
    console.warn('empty collection', categories, ids, this.items, this.removed);
    return null;
  }
};

CryptoCoinsCollection.prototype.getUpdated = function (ids, ts) {
  var items = this.items.sort(function (b, a) { return b.order - a.order });
  for (let i = 0; i < items.length; i++) {
    let item, j;
    for (j = 0; j < ids.length; j++) {
      if (items[i].id === ids[j]) {
        item = items[i];
        break;
      }
    }
    if (item) {
      if (ts[j] !== item.timestamp) {
        console.log(item.symbol, item.price, item.updated_at);
        return item;
      }
    }
  }
}

CryptoCoinsCollection.prototype.getNew = function () {
  for (let item of this.items) {
    if (item.added) {
      item.added = false;
      return item;
    }
  }
}

CryptoCoinsCollection.prototype.handleEvent = function (ev) {
  if (ev && ev.table) {
    if (ev.eventType === 'INSERT') {
      console.log('INSERT', ev)
    }
    if (ev.eventType === 'DELETE') {
      console.log('DELETE', ev)
    }
    if (ev.eventType === 'UPDATE' && ev.old && ev.new) {
      preprocessCoin(ev.new)
      if (!this.checkItem(ev.new)) {
        return false
      }
      const updated = this.updateItem(ev.new)
      return updated
    }
  }
}

export default CryptoCoinsCollection;
