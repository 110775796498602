import CanvasCollection from '../CanvasCollection';

function CryptoMarketCapCollection() {
  CanvasCollection.apply(this, arguments);
  this.name = 'CryptoMarketCapCollection';
}

CryptoMarketCapCollection.prototype = Object.create(CanvasCollection.prototype);
CryptoMarketCapCollection.prototype.constructor = CryptoMarketCapCollection;

/*
  id: 1,
  created_at: new Date().toISOString(),
  bitcoin_market_cap: data[1]['usd_market_cap'],
  bitcoin_market_cap_change: data[1]['usd_market_cap_change_percent_24hr'],
  altcoin_market_cap: (data[0]['total_market_cap'] / 100) * (100 - data[0]['bitcoin_dominance_percent']),
  altcoin_market_cap_change: -1 * data[1]['usd_market_cap_change_percent_24hr'],
  global_market_cap: data[0]['total_market_cap'],
  global_change: data[0]['total_market_cap_24hr_percent'],
*/

CryptoMarketCapCollection.prototype.checkItem = function(item) {
  if (
    item['id'] &&
    item['created_at'] &&
    item['bitcoin_market_cap'] && item['bitcoin_market_cap_change'] &&
    item['altcoin_market_cap'] && item['altcoin_market_cap_change'] &&
    item['global_market_cap'] && item['global_market_cap_change']
  ) {
    item['timestamp'] = +new Date(item['created_at']);
    item['type'] = 'market-cap';
    item['name'] = 'GLOBAL MARKET CAP';
    return true;
  }
  return false;
};

export default CryptoMarketCapCollection;
