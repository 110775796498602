import CanvasMapper from '../CanvasMapper';
import moment from '../../../moment';

function CryptoGreedIndexMapper() {
  CanvasMapper.apply(this, arguments);
  this.name = 'CryptoGreedIndexMapper';
}

CryptoGreedIndexMapper.prototype = Object.create(CanvasMapper.prototype);
CryptoGreedIndexMapper.prototype.constructor = CryptoGreedIndexMapper;

CryptoGreedIndexMapper.prototype.mapSettings = function(t, manifest, feed) {
  CanvasMapper.prototype.mapSettings.apply(this, arguments);
  return this
    .mapGreedIndexInfo(t, manifest);
};

CryptoGreedIndexMapper.prototype.mapItem = function(_, item, manifest) {
  var t = CanvasMapper.prototype.mapItem.apply(this, arguments);
  if (item) {
    t.id = item['timestamp'];
    t.type = 'greed-index';
    t.greed = {
      label: item['value_classification'],
      value: parseInt(item['value'], 10)
    };
  }
  return t;
};

CryptoGreedIndexMapper.prototype.mapGreedIndexInfo = function(t, manifest) {
  this.mapImageFromManifest(t, 'donut', manifest);
  this.mapImageFromManifest(t, 'arrow', manifest);
  return this;
};

export default CryptoGreedIndexMapper;
