import CanvasCollection from '../CanvasCollection';

function CryptoGreedIndexCollection() {
  CanvasCollection.apply(this, arguments);
  this.name = 'CryptoGreedIndexCollection';
}

CryptoGreedIndexCollection.prototype = Object.create(CanvasCollection.prototype);
CryptoGreedIndexCollection.prototype.constructor = CryptoGreedIndexCollection;

CryptoGreedIndexCollection.prototype.checkItem = function(item) {
  if (
    item['timestamp'] &&
    item['value'] &&
    item['value_classification']
  ) {
    item['type'] = 'greed-index';
    item['id'] = item['timestamp'];
    return true;
  }
  return false;
};

export default CryptoGreedIndexCollection;
