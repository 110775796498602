import CanvasMapper from '../CanvasMapper';

function CryptoMarketCapMapper() {
  CanvasMapper.apply(this, arguments);
  this.name = 'CryptoMarketCapMapper';
}

CryptoMarketCapMapper.prototype = Object.create(CanvasMapper.prototype);
CryptoMarketCapMapper.prototype.constructor = CryptoMarketCapMapper;

CryptoMarketCapMapper.prototype.mapSettings = function(t, manifest, feed) {
  CanvasMapper.prototype.mapSettings.apply(this, arguments);
  return this
    .mapImageFromManifest(t, 'up', manifest)
    .mapImageFromManifest(t, 'down', manifest);
};

CryptoMarketCapMapper.prototype.mapItem = function(_, item, manifest) {
  var t = CanvasMapper.prototype.mapItem.apply(this, arguments);

  if (item) {
    t.id = item['id'];
    t.type = item['type'];
    t.timestamp = item['timestamp'];
    t.name.text = String(item['name']).toUpperCase();

    t.global = { text: this.formatValue(item['global_market_cap']) };
    t.global.change = { value: item['global_market_cap_change'] };
    t.global.change.sign = (t.global.change.value > 0 ? '▲' : (t.global.change.value < 0 ? '▼' : ''));
    t.global.change.text = Math.abs(t.global.change.value).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});

    t.bitcoin = { text: this.formatValue(item['bitcoin_market_cap']) };
    t.bitcoin.change = { value: item['bitcoin_market_cap_change'] };
    t.bitcoin.change.sign = (t.bitcoin.change.value > 0 ? '▲' : (t.bitcoin.change.value < 0 ? '▼' : ''));
    t.bitcoin.change.text = Math.abs(t.bitcoin.change.value).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});

    t.altcoin = { text: this.formatValue(item['altcoin_market_cap']) };
    t.altcoin.change = { value: item['altcoin_market_cap_change'] };
    t.altcoin.change.sign = (t.altcoin.change.value > 0 ? '▲' : (t.altcoin.change.value < 0 ? '▼' : ''));
    t.altcoin.change.text = Math.abs(t.altcoin.change.value).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});


  }
  return t;
};

CryptoMarketCapMapper.prototype.formatValue = function (value) {
  const v = parseFloat(value, 10);

  if (v > 10000000000000) {
    return `${(v/1000000000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}T`;
  }
  if (v > 1000000000000) {
    return `${(v/1000000000000).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}T`;
  }
  if (v > 10000000000) {
    return `${(v/1000000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 1})}B`;
  }
  if (v > 1000000000) {
    return `${(v/1000000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 2})}B`;
  }
  if (v > 100000000) {
    return `${(v/1000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 1})}M`;
  }
  if (v > 1000000) {
    return `${(v/1000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 2})}M`;
  }
  if (v > 10000) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
  }
  if (v > 1000) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
  }
  if (v > 100) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
  }
  if (v > 0.1) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
  }
  if (v > 0.001) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 4, maximumFractionDigits: 4})}`;
  }
  return `${(v).toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 6})}`;
}

export default CryptoMarketCapMapper;
