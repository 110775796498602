export const template = {
  type: '',
  name: {
    text: '',
  },
  message: {
    text: '',
  },
  ticker: {
    text: '',
  },
  currency: {
    text: '',
  },
  value: {
    text: '',
  },
  volume: {
    text: '',
  },
  change: {
    text: '',
  },
  rank: {
    text: '',
  },
  supply: {
    text: '',
  },
  market: {
    text: '',
  },
  logo: {
    url: '',
  },
  video: {
    url: '',
  },
  source: {
    text: '',
  },
  tickers: [],
  sentiment: 'positive',
  collections: {},
  up: 'up',
  down: 'down',
  donut: 'donut',
  arrow: 'arrow',
  timeframe: 'history_1_day',
  change_timeframe: '',
  enable_ads: true,
  enable_dominance: true,
  history: [],
  colors: {},
  fonts: {
    header: 'Indivisible-Bold',
    regular: 'Indivisible-Regular',
    secondary: 'Indivisible-SemiBold'
  },
  duration: 5,
  loop: 7,
  language: 'en',
  performance: 'auto',
  random: true,
  font: 'Default',
  theme: 'Light',
  lite: false,
  patterns: {
    random_portrait: {
      w: 2, h: 4, x: 0, y: 0,
      animation: {
        duration: 1,
        delay: 8,
        durationScale: 1.25,
        delayScale: 1,
        zoomScale: 1.5,
        zoomDuration: 1,
      },
      random: true,
      pinnable: '',
      allowed: {
        'feed': true,
        'greed-index': true,
        'cta': true,
        'dominance': true,
        'favourite': true,
        'sponsored': true,
        'coin': true,
        'news': true,
        'video': true,
      },
      sequence: {
        order: 'auto',
        preserve: false,
        maximum: 50,
        gap: 3,
      },
      assets: {
        'feed': { frequency: 1 },
        'news': { frequency: 3, stay: 10 },
        'cta': { stay: 10 },
        'sponsored': { frequency: 5, stay: 10 },
        'video': { frequency: 5 },
      },
      video: { fullPlayback: true, breakAfter: true },
      pattern: [],
    },
    random_landscape: {
      w: 4, h: 2, x: 0, y: 0,
      animation: {
        duration: 1,
        delay: 8,
        durationScale: 1.25,
        delayScale: 1,
        zoomScale: 1.5,
        zoomDuration: 1,
      },
      random: true,
      pinnable: '',
      allowed: {
        'feed': true,
        'greed-index': true,
        'cta': true,
        'dominance': true,
        'favourite': true,
        'sponsored': true,
        'coin': true,
        'news': true,
        'video': true,
      },
      sequence: {
        order: 'auto',
        preserve: false,
        maximum: 50,
        gap: 3,
      },
      assets: {
        'feed': { frequency: 1 },
        'news': { frequency: 3, stay: 10 },
        'cta': { stay: 10 },
        'sponsored': { frequency: 5, stay: 10 },
        'video': { frequency: 5 },
      },
      video: { fullPlayback: true, breakAfter: true },
      pattern: [],
    },
    landscape: {
      w: 6,
      h: 2,
      x: 2,
      y: 0,
      count: 0,
      loop: 1,
      animation: {
        duration: 1,
        delay: 8,
        durationScale: 1.25,
        delayScale: 1,
        zoomScale: 1.5,
        zoomDuration: 1,
      },
      random: false,
      pinnable: '',
      allowed: {
        'feed': true,
        'greed-index': true,
        'cta': true,
        'dominance': true,
        'favourite': true,
        'sponsored': true,
        'coin': true,
        'news': true,
        'video': true,
      },
      video: { fullPlayback: true, breakAfter: true },
      pattern: [
        //INTRO
        [
          {x: 2, y: 0, w: 1, h: 1, animation: 'flip', direction: 'down', limit: 1, split: false, duration: 2, start: 0, delay: 0.0, type: 'feed'},
          {x: 2, y: 1, w: 1, h: 1, animation: 'flip', direction: 'up', limit: 1, split: false, duration: 2, start: 0, delay: 0.1, type: 'feed'},
          {x: 3, y: 0, w: 1, h: 1, animation: 'flip', direction: 'down', limit: 1, split: false, duration: 2, start: 0, delay: 0.3, type: 'feed'},
          {x: 3, y: 1, w: 1, h: 1, animation: 'flip', direction: 'up', limit: 1, split: false, duration: 2, start: 0, delay: 0.4, type: 'feed'},
          {x: 4, y: 0, w: 1, h: 1, animation: 'flip', direction: 'down', limit: 1, split: false, duration: 2, start: 0, delay: 0.6, type: 'feed'},
          {x: 4, y: 1, w: 1, h: 1, animation: 'flip', direction: 'up', limit: 1, split: false, duration: 2, start: 0, delay: 0.7, type: 'feed'},
          {x: 5, y: 0, w: 1, h: 1, animation: 'flip', direction: 'down', limit: 1, split: false, duration: 2, start: 0, delay: 0.9, type: 'feed'},
          {x: 5, y: 1, w: 1, h: 1, animation: 'flip', direction: 'up', limit: 1, split: false, duration: 2, start: 0, delay: 1.0, type: 'feed'}
        ],
        [
          {x: 2, y: 0, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'greed-index'}
        ],
        [
          {x: 2, y: 1, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 4, y: 1, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 3, y: 0, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 3, y: 1, w: 2, h: 1, animation: 'push', direction: 'push', limit: 0, split: false, duration: 1, type: 'feed', cat: ['video', 'landscape']}
        ],
        [
          {x: 2, y: 1, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 3, y: 1, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 3, y: 0, w: 1, h: 2, animation: 'slide', direction: 'down', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 1, w: 1, h: 1, animation: 'rotate', direction: 'left', limit: 1, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 0, y: 0, w: 1, h: 1, animation: 'slide', direction: 'right', limit: 1, split: false, duration: 0, type: 'greed-index', start: 0},
          {x: 1, y: 0, w: 1, h: 1, animation: 'slide', direction: 'right', limit: 1, split: false, duration: 0, type: 'feed', start: 0},
        ],
        [
          {x: 0, y: 0, w: 1, h: 2, animation: 'scale', direction: 'right', limit: 4, split: true, duration: 1, empty: true, intro: true},
          {x: 0, y: 0, w: 1, h: 1, animation: 'rotate', direction: 'right', limit: 4, split: false, duration: 1, delay: 1.0, empty: true, outro: true},
          {x: 2, y: 1, w: 1, h: 1, animation: 'rotate', direction: 'down', limit: 1, split: false, duration: 1, delay: 1.0, type: 'feed'},
        ],
        [
          {x: 4, y: 0, w: 1, h: 2, animation: 'rotate', direction: 'left', limit: 1, split: false, duration: 1, type: 'feed'},
        ],
        [
          {x: 2, y: 0, w: 2, h: 1, animation: 'rotate', direction: 'down', limit: 1, split: true, duration: 1, type: 'feed'},
        ],
        [
          {x: 2, y: 0, w: 2, h: 1, animation: 'slide', direction: 'up', limit: 0, split: true, duration: 1, delay: 0.1, empty: true},
          {x: 3, y: 1, w: 1, h: 1, animation: 'slide', direction: 'down', limit: 0, split: false, duration: 1, delay: 0.2, empty: true},
          {x: 2, y: 1, w: 1, h: 1, animation: 'slide', direction: 'down', limit: 0, split: false, duration: 1, empty: true},
          {x: 2, y: 0, w: 2, h: 2, animation: 'slice', direction: 'right', limit: 2, split: false, sliced: true, duration: 1, delay: 0.8, type: 'feed'}
        ],
        [
          {x: 4, y: 0, w: 1, h: 2, animation: 'rotate', direction: 'right', limit: 1, split: true, duration: 1, type: 'feed'},
        ],
        [
          {x: 2, y: 0, w: 2, h: 2, animation: 'slice-hide', direction: 'center', limit: 0, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 0, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'greed-index'}
        ],
        [
          {x: 3, y: 0, w: 2, h: 1, animation: 'mixed', direction: 'down', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 3, y: 0, w: 2, h: 1, animation: 'mixed', direction: 'up', limit: 0, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 4, y: 0, w: 1, h: 2, animation: 'slide', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 4, y: 0, w: 1, h: 2, animation: 'rotate', direction: 'right', limit: 1, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 1, w: 2, h: 1, animation: 'slide', direction: 'right', limit: 0, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 1, w: 2, h: 1, animation: 'rotate', direction: 'down', limit: 0, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 4, y: 0, w: 2, h: 1, animation: 'slide', direction: 'up', limit: 0, split: true, duration: 1, delay: 0.1, empty: true},
          {x: 5, y: 1, w: 1, h: 1, animation: 'slide', direction: 'down', limit: 0, split: false, duration: 1, delay: 0.2, empty: true},
          {x: 4, y: 1, w: 1, h: 1, animation: 'slide', direction: 'down', limit: 0, split: false, duration: 1, empty: true},
          {x: 4, y: 0, w: 2, h: 2, animation: 'slice', direction: 'right', limit: 2, split: false, sliced: true, duration: 1, delay: 0.8, type: 'feed'}
        ],
        [
          {x: 4, y: 0, w: 2, h: 2, animation: 'slice-hide', direction: 'center', limit: 0, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 0, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 1, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 3, y: 1, w: 2, h: 1, animation: 'push', direction: 'push', limit: 0, split: false, duration: 1, type: 'feed', cat: ['video', 'landscape']}
        ],
        [
          {x: 4, y: 1, w: 2, h: 1, animation: 'slide', direction: 'left', limit: 0, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 3, y: 0, w: 1, h: 2, animation: 'slide', direction: 'up', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 0, w: 1, h: 1, animation: 'rotate', direction: 'down', limit: 1, split: false, duration: 1, type: 'greed-index'}
        ],
        [
          {x: 4, y: 1, w: 2, h: 1, animation: 'slide', direction: 'down', limit: 0, split: true, duration: 1, delay: 0.1, empty: true},
          {x: 5, y: 0, w: 1, h: 1, animation: 'slide', direction: 'up', limit: 0, split: false, duration: 1, delay: 0.2, empty: true},
          {x: 4, y: 0, w: 1, h: 1, animation: 'slide', direction: 'up', limit: 0, split: false, duration: 1, empty: true},
          {x: 4, y: 0, w: 2, h: 2, animation: 'slice', direction: 'right', limit: 2, split: false, sliced: true, duration: 1, delay: 0.8, type: 'feed'}
        ],
        [
          {x: 3, y: 0, w: 1, h: 2, animation: 'rotate', direction: 'left', limit: 1, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 4, y: 0, w: 2, h: 2, animation: 'slice-hide', direction: 'center', limit: 0, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 3, y: 1, w: 2, h: 1, animation: 'mixed', direction: 'up', limit: 1, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 3, y: 1, w: 2, h: 1, animation: 'mixed', direction: 'down', limit: 1, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 1, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 3, y: 0, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 1, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 3, y: 0, w: 1, h: 2, animation: 'rotate', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'},
        ],
        [
          {x: 4, y: 0, w: 2, h: 1, animation: 'mixed', direction: 'left', limit: 0, split: true, duration: 1, type: 'feed'},
        ],
        [
          {x: 3, y: 0, w: 1, h: 2, animation: 'mixed', direction: 'left', limit: 1, split: true, duration: 1, type: 'feed'},
        ],
        [
          {x: 4, y: 0, w: 2, h: 1, animation: 'rotate', direction: 'up', limit: 0, split: true, duration: 1, type: 'feed'},
        ],
        [
          {x: 2, y: 0, w: 2, h: 1, animation: 'slide', direction: 'up', limit: 0, split: true, duration: 1, delay: 0.1, empty: true},
          {x: 3, y: 1, w: 1, h: 1, animation: 'slide', direction: 'down', limit: 0, split: false, duration: 1, delay: 0.2, empty: true},
          {x: 2, y: 1, w: 1, h: 1, animation: 'slide', direction: 'down', limit: 0, split: false, duration: 1, empty: true},
          {x: 2, y: 0, w: 2, h: 2, animation: 'slice', direction: 'right', limit: 2, split: false, sliced: true, duration: 1, delay: 0.8, type: 'feed'}
        ],
        [
          {x: 4, y: 1, w: 2, h: 1, animation: 'mixed', direction: 'down', limit: 1, split: true, duration: 1, type: 'feed'},
        ],
        [
          {x: 2, y: 0, w: 2, h: 2, animation: 'slice-hide', direction: 'center', limit: 0, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 0, w: 1, h: 1, animation: 'slide', direction: 'right', limit: 0, split: false, duration: 1, type: 'greed-index'},
        ],
        [
          {x: 4, y: 1, w: 2, h: 1, animation: 'rotate', direction: 'up', limit: 1, split: true, duration: 1, type: 'feed'},
        ],
        [
          {x: 3, y: 0, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 1, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 4, y: 1, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 3, y: 0, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 1, w: 1, h: 1, animation: 'mixed', direction: 'right', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 2, y: 1, w: 2, h: 1, animation: 'mixed', direction: 'down', limit: 0, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 4, y: 0, w: 1, h: 2, animation: 'slide', direction: 'up', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
        [
          {x: 4, y: 0, w: 1, h: 2, animation: 'slide', direction: 'left', limit: 1, split: true, duration: 1, delay: 0.1, empty: true},
          {x: 5, y: 0, w: 1, h: 1, animation: 'slide', direction: 'right', limit: 0, split: false, duration: 1, delay: 0.2, empty: true},
          {x: 5, y: 1, w: 1, h: 1, animation: 'slide', direction: 'right', limit: 0, split: false, duration: 1, empty: true},
          {x: 4, y: 0, w: 2, h: 2, animation: 'slice', direction: 'right', limit: 2, split: false, sliced: true, duration: 1, delay: 0.8, type: 'feed'}
        ],
        [
          {x: 2, y: 1, w: 2, h: 1, animation: 'mixed', direction: 'up', limit: 1, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 4, y: 0, w: 2, h: 2, animation: 'slice-hide', direction: 'center', limit: 0, split: true, duration: 1, type: 'feed'}
        ],
        [
          {x: 4, y: 0, w: 1, h: 1, animation: 'mixed', direction: 'left', limit: 0, split: false, duration: 1, type: 'feed'}
        ],
      ],
    },
  },
};

export const manifest = {
  images: {
    logo: { url: 'static/common/profile.png' },
    cc: { url: 'static/common/logo.svg', width: 463, height: 44, required: true },
    up: { url: 'static/socialcanvas/crypto/up.svg', width: 448, height: 512, required: true },
    down: { url: 'static/socialcanvas/crypto/down.svg', width: 448, height: 512, required: true },
    donut: { url: 'static/socialcanvas/crypto/donut-5.png', width: 482, height: 243, required: true },
    arrow: { url: 'static/socialcanvas/crypto/arrow-3.svg', width: 263, height: 263, required: true },
    cta: { url: 'static/socialcanvas/crypto/cta.svg', width: 1012, height: 540, required: true },
    sp: { url: 'static/socialcanvas/crypto/sp.png', width: 875, height: 1080, required: true },
  },
  introFonts: [],
  defaultFonts: [
    { name: 'Indivisible-Bold', path: '../static/fonts/' },
    { name: 'Indivisible-Regular', path: '../static/fonts/' },
    { name: 'Indivisible-SemiBold', path: '../static/fonts/' },
  ],
  fonts: {
    families: [],
  },
  styles: ['styles/social-canvas.css'],
};
