import { Launch } from '@lightningjs/sdk';
import App from './App.js';

if (window && window.Bugsnag) {
  Bugsnag.start({'apiKey': 'e471296fa76c1854f3d9527b28068384'});
}

export default function() {
  return Launch(App, ...arguments);
}
