import { Lightning } from '@lightningjs/sdk';
import {
  utils,
  SeenspireFontLoader,
  SeenspireImageLoader,
  SeenspireStage
} from 'private/seenspire';
import LngCryptoCanvasPresentation from './lng/canvas/crypto/CryptoCanvasPresentation';
import HtmlCryptoCanvasPresentation from './html/canvas/crypto/CryptoCanvasPresentation';
import realtime, { getSource } from './Realtime';
import { showLoader, hideLoader } from './loader';
import log from './log';


import * as crypto from './templates/crypto';
import * as ads from './templates/crypto-ads';

const PRESENTATIONS = {
  crypto: {
    ...crypto,
    LngPresentation: LngCryptoCanvasPresentation,
    HtmlPresentation: HtmlCryptoCanvasPresentation,
  },
  ads: {
    ...ads,
    LngPresentation: LngCryptoCanvasPresentation,
    HtmlPresentation: HtmlCryptoCanvasPresentation,
  },
};

function loadData(screenId, success, failed) {
  Promise.all([
    getSource(screenId, 'https://rssalcxvfhljmdrdrcye.supabase.co/functions/v1/get-news', 'news'),
    getSource(screenId, 'https://rssalcxvfhljmdrdrcye.supabase.co/functions/v1/fear-and-greed', 'greed-index', 'POST'),
    new Promise((resolve) => realtime.fetchAll(screenId, resolve)),
    new Promise((resolve) => realtime.fetchTrending(resolve)),
    new Promise((resolve) => realtime.fetchStats(resolve)),
    new Promise((resolve) => realtime.fetchMarketCap(resolve)),
  ]).then(success).catch(failed);
}

export default class Presentation extends Lightning.Component {
  constructor(stage, props) {
    super(stage, props);
    if (props.config) {
      this.render = 'html',
      this.presentationType = props.config.type;
      this.autoplay = props.config.autoplay;
      this.screenId = props.config.screenId;
      this.profileId = props.config.profileId;
      showLoader();
      this.loadData();
    }
  }

  loadData() {
    log('Presentation.loadData');
    if (this.screenId) {
      loadData(
        this.screenId,
        data => {
          this.dataLoaded(data);
        },
        () => this.failed()
      );
    }
    return this;
  }

  failed(callback, scope) {
    if (callback) {
      this.failedCallback = callback;
      this.failedCallbackScope = scope;
    } else if (this.failedCallback) {
      this.failedCallback.call(this.failedCallbackScope);
    }
    return this;
  }

  ready(callback, scope) {
    log('Presentation.ready');
    if (callback) {
      if (this.isReady === true) {
        callback.call(scope);
      } else {
        this.readyCallback = callback;
        this.readyCallbackScope = scope;
      }
    } else {
      this.isReady = true;
      if (this.readyCallback) {
        this.readyCallback.call(this.readyCallbackScope);
      }
    }
    return this;
  }

  updateSettings(payload) {
    log('Presentation.updateSettings');
    const data = {
      settings: this.feedSettings || {
        random: true,
        duration: 2,
        font: 'Default',
        performance: 'auto',
        pinnable: 'stats',
        allowed: {
          'feed': true,
          'greed-index': true,
          'dominance': true,
          'market-cap': true,
          'favourite': true,
          'sponsored': false,
          'coin': true,
          'news': true,
          'video': false,
        },
        audio: false,
      }
    };

    let silent = false;
    let resubscribe = false;
    const settings = payload && payload.new;
    if (settings) {
      data.settings.random = settings.shuffle || data.settings.random;
      data.settings.duration = settings.transition_speed || data.settings.duration;
      data.settings.favourite =
        settings.pinnable !== 'stats' &&
        settings.pinnable !== 'greed-index' &&
        settings.pinnable !== '"greed-index"' &&
        !!settings.pinnable &&
        settings.pinnable;
      data.settings.pinnable = data.settings.favourite ? 'favourite' : 'stats';
      data.settings.allowed['market-cap'] = !!settings.enable_market_cap;
      data.settings.allowed['dominance'] = !!settings.enable_dominance;
      data.settings.allowed['news'] = !!settings.enable_news;
      data.settings.allowed['greed-index'] = !!settings.enable_fear_greed;
      data.settings.allowed['sponsored'] = this.template.enable_ads;
      data.settings.allowed['video'] = this.template.enable_ads;
      if (
        data.settings.pinnable === 'stats' &&
        !data.settings.allowed['dominance'] &&
        !data.settings.allowed['greed-index'] &&
        !data.settings.allowed['market-cap']
      ) {
        data.settings.pinnable = false;
      }
      if (data.settings.timeframe !== settings.timeframe) {
        data.settings.timeframe = settings.timeframe
        silent = true
      }
      if (data.settings.trending !== settings.show_trending) {
        data.settings.trending = settings.show_trending
        silent = true
        resubscribe = true
      }
    }

    const coinSource = data.settings.trending ? this.rawData['trending'] : this.rawData['coin'];
    const favourite =
      this.rawData['coin'].find(d => d.symbol === data.settings.favourite) ||
      this.rawData['trending'].find(d => d.symbol === data.settings.favourite);

    if (data.settings.pinnable !== 'favourite') {
      data['coin'] = coinSource || [];
      data['favourite'] = [];
    } else {
      data['coin'] = coinSource && coinSource.filter(d => d.symbol !== data.settings.favourite) || [];
      data['favourite'] = favourite ? [favourite] : [];
    }

    this.feedSettings = data.settings;

    this.presentation.updateCollection('favourite', data['favourite']);
    this.presentation.updateCollection('coin', data['coin'], silent);
    this.presentation.updateSettings();
    if (resubscribe) {
      this.presentation.registerEvents();
    }
  }

  prepareData(payload) {
    log('Presentation.prepareData');
    const data = {
      settings: {
        random: true,
        duration: 5,
        font: 'Default',
        performance: 'auto',
        pinnable: 'stats',
        timeframe: 'history_1_day',
        trending: false,
        allowed: {
          'feed': true,
          'greed-index': true,
          'dominance': true,
          'market-cap': true,
          'favourite': true,
          'sponsored': true,
          'video': true,
          'coin': true,
          'news': true,
        },
        audio: false,
      }
    }

    const assets = payload[2] && payload[2][0] && payload[2][0].profile && payload[2][0].profile.profile_assets || []
    const trending = payload[3] || []

    // LIMIT
    this.rawData = {
      'coin': assets.map(a => a.assets),
      'trending': trending
    };
    // FAKE LIMIT
    // this.rawData['coin'] = this.rawData['coin'].filter(d => ['BTC', 'USDT', 'SOL', 'DOGE', 'XRP', 'LINK', 'AVAX', 'ETH'].indexOf(d.symbol) > -1)

    const settings = payload[2] && payload[2][0] && payload[2][0].screen_settings || {}
    if (settings) {
      data.settings.random = settings.shuffle || data.settings.random;
      data.settings.duration = settings.transition_speed || data.settings.duration;
      data.settings.favourite =
        settings.pinnable !== 'stats' &&
        settings.pinnable !== 'greed-index' &&
        settings.pinnable !== '"greed-index"' &&
        !!settings.pinnable &&
        settings.pinnable;
      data.settings.pinnable = data.settings.favourite ? 'favourite' : 'stats';
      data.settings.allowed['dominance'] = !!settings.enable_dominance;
      data.settings.allowed['market-cap'] = !!settings.enable_market_cap;
      data.settings.allowed['news'] = !!settings.enable_news;
      data.settings.allowed['greed-index'] = !!settings.enable_fear_greed;
      data.settings.allowed['sponsored'] = this.template.enable_ads;
      data.settings.allowed['video'] = this.template.enable_ads;
      if (
        data.settings.pinnable === 'stats' &&
        !data.settings.allowed['dominance'] &&
        !data.settings.allowed['greed-index'] &&
        !data.settings.allowed['market-cap']
      ) {
        data.settings.pinnable = false;
      }
      data.settings.timeframe = settings.timeframe || 'history_1_day';
      data.settings.trending = settings.show_trending || false;
    }
    const coinSource = data.settings.trending ? this.rawData['trending'] : this.rawData['coin'];
    const favourite =
      this.rawData['coin'].find(d => d.symbol === data.settings.favourite) ||
      this.rawData['trending'].find(d => d.symbol === data.settings.favourite);

    if (data.settings.pinnable !== 'favourite') {
      data['favourite'] = [];
      data['coin'] = coinSource;
    } else {
      data['favourite'] = favourite ? [favourite] : [];
      data['coin'] = coinSource.filter(d => d.symbol !== data.settings.favourite);
    }

    data['video'] = [
      {
        "id": 1,
        "hidden": true,
        "url": "static/socialcanvas/crypto/videos/video-1.mp4",
        "duration": 30,
        "width": 1280,
        "height": 720,
        "date": "Mon, 28 Aug 2023 10:18:16 -0400"
      },
      {
        "id": 2,
        "hidden": true,
        "url": "static/socialcanvas/crypto/videos/video-2.mp4",
        "duration": 37,
        "width": 720,
        "height": 1280,
        "date": "Mon, 28 Aug 2023 10:18:16 -0400"
      },
      {
        "id": 3,
        "hidden": true,
        "url": "static/socialcanvas/crypto/videos/video-3.mp4",
        "duration": 40,
        "width": 720,
        "height": 1280,
        "date": "Mon, 28 Aug 2023 10:18:16 -0400"
      },
      {
        "id": 4,
        "url": "static/socialcanvas/crypto/videos/video-4.mp4",
        "duration": 15,
        "width": 1500,
        "height": 910,
        "date": "Mon, 28 Aug 2023 10:18:16 -0400"
      },
      {
        "id": 5,
        "url": "static/socialcanvas/crypto/videos/video-5.gif",
        "duration": 10,
        "width": 1200,
        "height": 675,
        "date": "Mon, 28 Aug 2023 10:18:16 -0400"
      }
    ];
    data['news'] = payload[0].data || [];
    data['greed-index'] = payload[1].data || [];
    data['dominance'] = payload[4] && [payload[4][0]] || [];
    data['market-cap'] = payload[4] && [payload[4][1]] || [];
    data['sponsored'] = [
      {
        "id": 1,
        "imageUrl": "static/socialcanvas/crypto/sponsored/sponsored-1.png",
        "text": "Adidas creates residency for NFT artists spanning the digital and physical realms.",
        "width": 1922,
        "height": 2160,
        "date": "Wed, 23 Aug 2023 13:12:16 -0400"
      },
      {
        "id": 2,
        "imageUrl": "static/socialcanvas/crypto/sponsored/sponsored-2.png",
        "text": "SEC Delays BTC ETF Decision; Swift-Chainlink Experiment Succeeds; X Expands Payment Licenses",
        "width": 1922,
        "height": 2160,
        "date": "Mon, 28 Aug 2023 10:18:16 -0400"
      },
      {
        "id": 3,
        "imageUrl": "static/socialcanvas/crypto/sponsored/sponsored-3.png",
        "text": "",
        "width": 480,
        "height": 1080,
        "date": "Mon, 28 Aug 2023 10:18:16 -0400"
      },
      {
        "id": 4,
        "imageUrl": "static/socialcanvas/crypto/sponsored/sponsored-4.png",
        "text": "",
        "width": 480,
        "height": 1080,
        "date": "Mon, 28 Aug 2023 10:18:16 -0400"
      }
    ];
    data['profile_id'] = payload[2] && payload[2][0] && payload[2][0].profile_id

    return data;
  }

  dataLoaded(payload) {
    log('Presentation.dataLoaded');

    this.copyTemplate();
    this.loadManifest();
    this.loadFonts();

    const data = this.prepareData(payload)
    this.feedSettings = data.settings;
    this.feedItems = data;
    this.profileId = data['profile_id'];

    this.dataReady = true;
    this.onReady();
  }

  copyTemplate() {
    log('Presentation.copyTemplate');
    if (PRESENTATIONS[this.presentationType]) {
      this.template = utils.copy(PRESENTATIONS[this.presentationType].template);
      this.manifest = utils.copy(PRESENTATIONS[this.presentationType].manifest);
      this.translations = utils.copy(PRESENTATIONS[this.presentationType].translations);
    }
    return this;
  }

  loadManifestImages(success, failed, scope) {
    log('Presentation.loadManifestImages');
    if (!this.imageLoader) {
      this.imageLoader = new SeenspireImageLoader();
    }
    this.imageLoader.preloadMultiple(this.manifest.images, success, scope);
    return this;
  }

  loadManifest() {
    log('Presentation.loadManifest');
    return this
      .loadManifestImages(this.onImagesReady, undefined, this);
  }

  preloadManifest(success, failed, scope) {
    log('Presentation.preloadManifest');
    return this.loadManifestImages(success, failed, scope);
  }

  addFont(font, path, callback, scope) {
    log('Presentation.addFont');
    if (font && font !== 'Default' && this.fontLoader && !this.fontLoader.isSystemFont(font)) {
      font = font.replace(/ /g, '');
      if (this.manifest && this.manifest.fonts && this.manifest.fonts.families) {
        if (path) {
          path = path === 'assets/fonts' ? 'static/fonts/' : path;
          this.addFontToManifest({ name: font + '-Regular', path: path });
          this.addFontToManifest({ name: font + '-Bold', path: path });
        } else {
          this.addFontToManifest({ name: font + '-Regular', path: 'static/fonts/' });
          this.addFontToManifest({ name: font + '-Bold', path: 'static/fonts/' });
        }
      }
    }
    return this.loadManifestFonts(callback, scope);
  }

  addFontToManifest(font) {
    log('Presentation.addFontToManifest');
    if (this.manifest?.fonts?.families) {
      for (let i in this.manifest.fonts.families) {
        if (f[i].name === font.name) {
          return this;
        }
      }
      f.push(font);
    }
    return this;
  }

  loadManifestFonts(callback, scope) {
    log('Presentation.loadManifestFonts');
    if (this.manifest?.fonts) {
      this.fontLoader.preloadMultiple(
        this.manifest.fonts.families
          .concat(this.manifest.fonts.introFonts)
          .concat(this.manifest.fonts.defaultFonts),
        function() {
          callback.call(scope);
        }
      );
    }
    return this;
  }

  loadFonts() {
    log('Presentation.loadFonts');
    this.fontLoader = new SeenspireFontLoader();
    this.fontLoader.preloadMultiple(this.manifest.fonts, this.onFontsReady, this);
    return this;
  }

  onFontsReady() {
    log('Presentation.onFontsReady');
    this.fontsReady = true;
    return this.onReady();
  }

  onImagesReady() {
    log('Presentation.onImagesReady');
    this.imagesReady = true;
    return this.onReady();
  }

  onReady() {
    log('Presentation.onReady');
    if (this.fontsReady && this.dataReady && this.imagesReady) {
      if (!this.loaded) {
        this.loaded = true;
        if (this.render === 'html') {
          this.createHtmlPresentation();
        }
        if (this.render === 'lng') {
          this.createLngPresentation();
        }
      }
    }
    return this;
  }

  createLngPresentation() {
    log('Presentation.createLngPresentation');
    this.presentation = new PRESENTATIONS[this.presentationType].LngPresentation(this.stage, {
      clipping: true,
      rect: true,
      visible: true,
      alpha: 0.01,
      color: '0x00000000',
      x: 0,
      y: 0,
      w: window.innerWidth,
      h: window.innerHeight,
      type: PRESENTATIONS[this.presentationType].Presentation,
      config: {
        app: this,
        template: this.template,
        manifest: this.manifest,
      },
    });
    this.childList.add(this.presentation);
    return this.start();
  }

  createHtmlPresentation() {
    log('Presentation.createHtmlPresentation');
    this.htmlStage = new SeenspireStage('stage', { });
    this.htmlStage.ready(() => {
      this.presentation = new PRESENTATIONS[this.presentationType].HtmlPresentation('presentation', {
        app: this,
      }).onFrame(() => this.start());
    });
    return this;
  }

  remove() {
    log('Presentation.remove');
    if (this.presentation) {
      this.childList.remove(this.presentation);
      delete this.presentation;
      this.stage.gc();
    }
    return this;
  }

  start() {
    log('Presentation.start');
    if (this.presentation) {
      this.presentation.ready(() => {
        this.onPresentationReady();
      });
      this.presentation.start();
      this.initTimers();
      hideLoader();
    }
    return this;
  }

  onPresentationReady() {
    log('Presentation.onPresentationReady');
    if (this.render === 'lng') {
      if (this.autoplay) {
        this.presentation.visible = true;
        this.presentation.alpha = 1;
        this.presentation.play();
      }
      this.ready();
    }
    if (this.render === 'html') {
      if (!this.started) {
        this.started = true;
        this.play();
      }
    };
    return this;
  }

  play() {
    log('Presentation.play');
    if (this.presentation && this.presentation.play) {
      this.presentation.play();
    }
    return this;
  };

  show(start = 0) {
    log('Presentation.show');
    if (this.presentation) {
      const animation = this.presentation.animation({
        duration: 0.6,
        delay: start,
        actions: [
          {
            p: 'alpha',
            v: {
              0: { sm: 0.3, v: 0 },
              1: { sm: 0, v: 1 },
            },
          },
        ],
      });

      const promise = new Promise(resolve => {
        animation.on('finish', () => {
          this.play();
          resolve();
        });
      });

      animation.start();

      return promise;
    }
    return Promise.resolve();
  }

  hide(start = 0) {
    log('Presentation.hide');
    if (this.presentation) {
      const animation = this.presentation.animation({
        duration: 0.6,
        delay: start,
        actions: [
          {
            p: 'alpha',
            v: {
              0: { sm: 0, v: 1 },
              1: { sm: 0.3, v: 0 },
            },
          },
        ],
      });

      const promise = new Promise(resolve => {
        animation.on('finish', resolve);
      });

      animation.start();

      return promise;
    }
    return Promise.resolve();
  }

  play() {
    log('Presentation.play');
    if (this.presentation) {
      this.presentation.play();
    }
    return this;
  }

  out(callback, scope) {
    log('Presentation.out');
    if (this.presentation) {
      this.presentation.out(callback, scope);
    }
    return this;
  }

  setLimit(limit, isSlides, limitCallback, limitScope) {
    log('Presentation.setLimit');
    if (this.presentation) {
      this.presentation.pps = {
        limit,
        limitSlides: isSlides,
        limitStories: !isSlides,
        limitCallback,
        limitScope,
      };
    }
    return this;
  }

  addPixel(url, name) {
    log('Presentation.addPixel');
    if (this.htmlStage && this.htmlStage.addPixel) {
      this.htmlStage.addPixel(url, name);
    }
    return this;
  }

  updatePixel(url, name) {
    log('Presentation.updatePixel');
    if (this.htmlStage && this.htmlStage.updatePixel) {
      this.htmlStage.updatePixel(url, name);
    }
    return this;
  }

  addPixelsFromManifest(success, scope, bypass) {
    log('Presentation.addPixelsFromManifest');
    for (let i in this.manifest.images) {
      if (this.manifest.images[i].pixel) {
        this.addPixel(this.manifest.images[i].url, i);
      }
    }
    return this.htmlStage.onFrame(success, scope, bypass);
  }

  clearPixels(names) {
    log('Presentation.clearPixels');
    if (this.htmlStage && this.htmlStage.clearPixels) {
      this.htmlStage.clearPixels(names);
    }
    return this;
  }

  // helpers
  togglePin() {
    const settings = {
      new: {
        transition_speed: 3,
        pinnable: Math.random() > 0.5 ? 'greed-index' : 'BTC',
        timeframe: 'history_1_day',
        enable_fear_greed: !this.feedSettings.pinnable,
        enable_news: true,
        trending: false
      }
    }
    this.updateSettings(settings)
    return this
  }

  togglePinnables(fear, dominance, marketcap) {
    const settings = {
      new: {
        transition_speed: 3,
        pinnable: 'stats',
        timeframe: 'history_1_day',
        enable_fear_greed: fear,
        enable_dominance: dominance,
        enable_market_cap: marketcap,
        enable_news: true,
        trending: false
      }
    }
    this.updateSettings(settings)
    return this
  }

  loadAssets() {
    console.log('LOAD ASSETS')
    getSource(this.screenId, 'https://rssalcxvfhljmdrdrcye.supabase.co/functions/v1/screen-id-to-user-assets', 'coin', 'POST')
    .then(payload => {
      console.log(payload.data)
      console.log(this.presentation)
      console.log(this.feedSettings.pinnable)
      if (this.presentation && payload && payload.data) {
        this.rawData['coin'] = payload.data;

        if (this.feedSettings.pinnable === 'favourite') {
          const coin = this.rawData['coin'].filter(d => d.symbol !== this.feedSettings.favourite)
          const favourite = this.rawData['coin'].filter(d => d.symbol === this.feedSettings.favourite)
          this.presentation.updateCollection('coin', coin)
          this.presentation.updateCollection('favourite', favourite)
        } else {
          console.log('UPDATE COINS LIST', this.rawData['coin'].map(c => c.symbol).join(', '))
          this.presentation.updateCollection('coin', this.rawData['coin'])
        }
        this.presentation.registerEvents()
      }
    })
    .catch(err => log(err))
  }

  initTimers() {
    log('Presentation.initTimers');
    this.timerFearAndGreed && clearInterval(this.timerFearAndGreed);
    this.timerNews && clearInterval(this.timerNews);
    this.timerCoins && clearInterval(this.timerCoins);
    this.timerDominance && clearInterval(this.timerDominance);
    this.timerMarketCap && clearInterval(this.timerMarketCap);

    this.timerFearAndGreed = setInterval(() => {
      log('load fear & greed');
      getSource(this.screenId, 'https://rssalcxvfhljmdrdrcye.supabase.co/functions/v1/fear-and-greed', 'greed-index', 'POST')
        .then(payload => this.presentation && payload && payload.data && this.presentation.updateCollection('greed-index', payload.data))
        .catch(err => log(err))
    }, 360000);

    this.timerNews = setInterval(() => {
      log('load news');
      getSource(this.screenId, 'https://rssalcxvfhljmdrdrcye.supabase.co/functions/v1/get-news', 'news')
        .then(payload => this.presentation && payload && payload.data && this.presentation.updateCollection('news', payload.data))
        .catch(err => log(err))
    }, 360000);

    this.timerDominance = setInterval(() => {
      log('load dominance');
      new Promise((resolve) => realtime.fetchStats(resolve))
        .then(data => {
          if (!this.presentation || !data || !data.length) return
          this.presentation.updateCollection('dominance', [data[0]])
          this.presentation.updateCollection('market-cap', [data[1]])
        })
        .catch(err => log(err))
    }, 360000);

    this.timerMarketCap = setInterval(() => {
      log('load market cap')
      new Promise((resolve) => realtime.fetchMarketCap(resolve))
        .then(data => this.presentation && data && this.presentation.updateCollection('market-cap', data))
        .catch(err => log(err))
    }, 360000);

    this.timerCoins = setInterval(() => {
      this.loadAssets();
    }, 30000);
  }

  destroy() {
    log('Presentation.destroy');
    this.timerFearAndGreed && clearInterval(this.timerFearAndGreed);
    this.timerNews && clearInterval(this.timerNews);
    this.timerCoins && clearInterval(this.timerCoins);
    this.timerDominance && clearInterval(this.timerDominance);
    this.timerMarketCap && clearInterval(this.timerMarketCap);
    if (this.presentation) {
      this.presentation.destroy();
    }
  }

  static _template() {
    return {
      w: window.innerWidth,
      h: window.innerHeight,
      color: '0x00000000',
      rect: true,
      clipping: true,
    };
  }
}
