import CanvasCollection from '../CanvasCollection';

function CryptoDominanceCollection() {
  CanvasCollection.apply(this, arguments);
  this.name = 'CryptoDominanceCollection';
}

CryptoDominanceCollection.prototype = Object.create(CanvasCollection.prototype);
CryptoDominanceCollection.prototype.constructor = CryptoDominanceCollection;

/*
  id: 1,
  created_at: new Date().toISOString(),
  bitcoin_market_cap: data[1]['usd_market_cap'],
  bitcoin_volume: data[1]['volume_usd_24hr'],
  bitcoin_market_cap_change: data[1]['usd_market_cap_change_percent_24hr'],
  bitcoin_dominance: data[0]['bitcoin_dominance_percent']
*/

CryptoDominanceCollection.prototype.checkItem = function(item) {
  if (
    item['id'] &&
    item['created_at'] &&
    item['bitcoin_market_cap'] &&
    item['bitcoin_volume'] &&
    item['bitcoin_market_cap_change'] &&
    item['bitcoin_dominance']
  ) {
    item['timestamp'] = +new Date(item['created_at']);
    item['type'] = 'dominance';
    item['symbol'] = 'BTC';
    item['name'] = 'DOMINANCE';
    return true;
  }
  return false;
};

export default CryptoDominanceCollection;
